.smartData {
  position: absolute;
  left: 10px;
  left: 20px;
  top: 10px;

  height: 100px;
  min-width: 300px;
  display: inline-block;

  padding: 10px;
  color: hsl(0, 0%, 98%)
}
.temperature {
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    display: inline-block;
    vertical-align: 40px;
    font-size: 2.8rem;
}

.weather {
  display: inline-block;
  position: absolute;
  top: -10px;
}